<template>
  <v-app>
    <v-main>
      <v-img
        :class="{
          'v-image--sm': this.$vuetify.breakpoint.smAndDown,
          'v-image--md': this.$vuetify.breakpoint.mdAndDown
        }"
        src="@/assets/MMS-Login-Page.jpg"
        min-height="100%"
      >
        <v-container
          id="router-container"
          class="error-page align-start"
          tag="section"
          fill-height
        >
          <v-row class="text-center justify-center white--text mt-12">
            <v-col cols="auto">
              <div class="text-h2 my-10">
                We're down for maintenance...
              </div>
              <div class="text-h6 my-10">
                This page is undergoing maintenance and will be back soon
              </div>
              <v-icon
                x-large
                color="white"
              >
                mdi-wrench-clock
              </v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'Maintenance'
}
</script>
